//@author: devin

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChange,
    SimpleChanges,
    TemplateRef,
    ViewEncapsulation,
    ViewRef,
} from "@angular/core";
import { DropDownItem } from "../../pojo/DropDownItem";
import { FavoriteApi } from "../s25-favorite/favorite.api";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { DropDownPaginatedModel } from "../../pojo/DropDownPaginatedModel";
import { Defer, jSith } from "../../util/jquery-replacement";
import { UserprefService } from "../../services/userpref.service";
import { FavoriteService } from "../../services/favorite.service";
import { DropdownApi } from "./dropdown.api";
import { InfiniteScrollUtil } from "../s25-infinite-scroll/infinite.scroll.util";
import { S25LoadingApi } from "../s25-loading/loading.api";

@TypeManagerDecorator("s25-dropdown-paginated")
@Component({
    selector: "s25-dropdown-paginated",
    template: `
        <div class="ngDropdownPaginated" tabindex="0" [attr.id]="id">
            <div *ngIf="init && model" (click)="toggle()">
                <div
                    class="ui-select-container select2 select2-container"
                    [ngClass]="{ 'select2-container-active select2-dropdown-open open': isOpen }"
                >
                    <a class="select2-choice ui-select-match" [ngClass]="{ 'select2-default': isEmpty }">
                        <div *ngIf="model.alwaysOpen">
                            {{ model.placeholder }}
                        </div>

                        <div *ngIf="!model.alwaysOpen">
                            <div *ngIf="model.noSelection" class="select2-chosen ngNoSelection">
                                {{ model.noSelection }}
                            </div>

                            <div *ngIf="!model.noSelection" class="ngDropdownContent">
                                <div
                                    *ngIf="chosen && chosen.dropDownOrigName && initChosen; else elseBlock"
                                    class="ngSelectFavorite--wrapper"
                                >
                                    <s25-favorite
                                        class="ngSearchSelectFavorite"
                                        *ngIf="
                                            isLoggedIn &&
                                            model.hasFav &&
                                            chosen?.itemId &&
                                            !chosen.isCustomAttribute &&
                                            !this.model.useChoiceForChosen
                                        "
                                        [item]="chosen"
                                    >
                                        <span class="ngFloatRight ngClearBoth"></span>
                                    </s25-favorite>
                                    <s25-item-generic
                                        *ngIf="
                                            chosen?.itemTypeId <= 6 &&
                                            !chosen.isCustomAttribute &&
                                            !this.model.useChoiceForChosen
                                        "
                                        [modelBean]="chosen"
                                        [inactive]="true"
                                    ></s25-item-generic>

                                    <span
                                        *ngIf="
                                            (!(chosen?.itemTypeId <= 6) || chosen.isCustomAttribute) &&
                                            !model.useChoiceForChosen
                                        "
                                        [innerHTML]="chosen.dropDownOrigName"
                                        class="select2-chosen ngSelectFavorite--text"
                                    >
                                    </span>
                                    <span
                                        *ngIf="this.chosen && this.model.useChoiceForChosen"
                                        class="select2-chosen ngSelectFavorite--text"
                                    >
                                        <ng-container
                                            *ngTemplateOutlet="
                                                choice;
                                                context: {
                                                    item: chosen,
                                                    chosen: true,
                                                    $transcludeCtrl: this,
                                                    $transcludeSource: source,
                                                }
                                            "
                                        ></ng-container>
                                    </span>
                                </div>

                                <ng-template #elseBlock class="c-padding-right--single">
                                    <span class="select2-chosen" *ngIf="!isEmpty || model.serverSide">
                                        {{ model.placeholder }}
                                    </span>
                                    <span class="select2-chosen" *ngIf="isEmpty && !model.serverSide">
                                        {{ model.emptyText }}
                                    </span>
                                </ng-template>
                            </div>
                        </div>

                        <ng-content select="[options]"></ng-content>
                        <span class="select2-arrow ui-select-toggle"><b>&nbsp;</b></span>
                    </a>
                    <div
                        class="ui-select-dropdown select2-drop ngDropdownDrop select2-with-searchbox select2-drop-active"
                        *ngIf="isOpen"
                    >
                        <div *ngIf="model.searchEnabled" class="search-container select2-search">
                            <input
                                *ngIf="!model.autoOpen"
                                (click)="blockClose($event)"
                                type="search"
                                placeholder="{{ model.placeholder }}"
                                [hidden]="model.hideSearch"
                                data-autocomplete="off"
                                data-autocorrect="off"
                                data-autocapitalize="off"
                                data-spellcheck="false"
                                data-role="combobox"
                                data-aria-expanded="true"
                                aria-label="Select box"
                                class="ui-select-search select2-input"
                                id="searchAutoFocus"
                                [(ngModel)]="search"
                            />

                            <input
                                *ngIf="model.autoOpen"
                                (click)="blockClose($event)"
                                type="search"
                                placeholder="{{ model.placeholder }}"
                                [hidden]="model.hideSearch"
                                data-autocomplete="off"
                                data-autocorrect="off"
                                data-autocapitalize="off"
                                data-spellcheck="false"
                                data-role="combobox"
                                data-aria-expanded="true"
                                aria-label="Select box"
                                class="ui-select-search select2-input"
                                id="searchAutoFocus"
                                [(ngModel)]="search"
                            />

                            <s25-loading-inline [model]="{}"></s25-loading-inline>
                        </div>

                        <ul
                            s25-typeahead
                            [action]="searchF"
                            [delayMs]="typeaheadDelay"
                            s25-infinite-scroll
                            [onScroll]="scrollF"
                            [hasMorePages]="hasMorePages"
                            [topSelector]="topSelector"
                            [ready]="infiniteDataPromise.promise"
                            class="ngDropdownScrollTopEl ui-select-choices ui-select-choices-content select2-results select2-result-sub"
                            aria-live="polite"
                        >
                            <li *ngIf="searchError">There was a problem retrieving results try a more specific term</li>
                            <li
                                tabindex="0"
                                class="ngDropdownItemEl select2-result-label {{
                                    item && item.isGroup
                                        ? 'ngDropdownGroup'
                                        : item && !item.items
                                          ? 'ui-select-choices-row'
                                          : 'c-nestedDropdown'
                                }}"
                                *ngFor="let item of currResults | slice: 0 : itemsLimit"
                            >
                                <div
                                    class="ngBold ui-select-choices-group-label select2-result-label"
                                    *ngIf="item && item.isGroup"
                                >
                                    {{ item.groupName }}
                                </div>
                                <s25-dropdown-item
                                    [item]="item"
                                    [parent]="this"
                                    [choice]="choice"
                                    [arrayChoice]="arrayChoice"
                                ></s25-dropdown-item>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class S25DropdownPaginatedComponent implements OnInit, OnDestroy, OnChanges {
    @Input() model: DropDownPaginatedModel = undefined;
    @Input() chosen: DropDownItem = undefined;
    @Input() onSelect: Function = undefined;
    @Input() choice: TemplateRef<any>;
    @Input() arrayChoice?: TemplateRef<any>;

    @Output() chosenChange = new EventEmitter<any>();
    @Output() isOpenEmit = new EventEmitter<boolean>();

    public static dropdownCount: number = 2000;

    initChosen = true;
    uuid: string;
    id: string;
    maxint = 2147483647;
    pageSize = 25;
    pagesShown = 1;
    itemsLimit = this.pageSize;
    isOpen = false;
    currItem: any = null;
    source: any = null; //ref caller sets for use in item template via $transcludeSource
    maxPathVal = -1;
    groups: DropDownItem[]; //dummy labels to group items by in dropdown
    hasGroups: boolean;
    itemNum: number;
    isEmpty: boolean; //is dropdown empty of items?
    search: string; //search key used to filter items
    lastSearch: string;
    currResults: DropDownItem[];
    $scrollTopElem: any;
    topSelector: string;
    infiniteDataPromise: Defer = jSith.defer();
    scrollWorking: boolean;
    scrollBean: any;
    isLoggedIn: boolean;
    init: boolean;
    typeaheadDelay: number = 250;
    searchError: boolean;

    copySkipMap = { popoverTemplate: true, data: true };

    constructor(
        private elementRef: ElementRef,
        private zone: NgZone,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this;

        S25DropdownPaginatedComponent.dropdownCount++;
        this.uuid = "" + S25DropdownPaginatedComponent.dropdownCount;
        this.id = "ngDropdown-" + this.uuid;
        this.topSelector = "#" + this.id + " .ngDropdownScrollTopEl";

        /********************
         * Event Handlers
         ********************/

        //close drop down on click anywhere (stopped by stopPropagation call if click is within element)
        jSith.on(window, "click", () => {
            this.close();
        });

        jSith.on(this.elementRef.nativeElement, "click", (e: any) => {
            //on click within element, stop propagation to window
            e.stopPropagation();
        });

        jSith.on(this.elementRef.nativeElement, "keydown", (e: any) => {
            if ((e.which === 40 || e.key === "ArrowDown" || (e.which === 9 && !e.shiftKey)) && this.isOpen) {
                //down arrow or tab
                e.stopPropagation();
                e.preventDefault();
                this.arrow("down");
            } else if ((e.which === 38 || e.key === "ArrowUp" || (e.which === 9 && e.shiftKey)) && this.isOpen) {
                //up arrow or shift-tab
                e.stopPropagation();
                e.preventDefault();
                this.arrow("up");
            } else if ((e.key === "ArrowRight" || e.key === "ArrowLeft") && this.isOpen) {
                e.stopPropagation();
                e.preventDefault();
                this.arrow("leftRight");
            } else if (e.which === 32) {
                //space
                if (!this.isOpen) {
                    //only open if not already open, otherwise users cannot type the space character in a search
                    e.stopPropagation();
                    e.preventDefault();
                    this.open();
                }
            } else if (e.which === 27) {
                //escape
                e.stopPropagation();
                e.preventDefault();
                this.close();
            } else if (e.which === 13 || e.which === 10) {
                //enter
                if (!this.isOpen) {
                    //only open if not already open
                    e.stopPropagation();
                    e.preventDefault();
                    this.open();
                } else {
                    this.zone.run(() => {
                        let item = this.getItem(this.currItem);
                        if (item && item.items) {
                            this.enterSelect(e);
                        } else {
                            if (item && item.onItemSelect) {
                                item.onItemSelect();
                            }
                            this.enterSelect(e);
                        }
                    });
                }
            }
        });
    }

    blockClose = (e: any) => {
        e && e.stopPropagation && e.stopPropagation();
    };

    setName = (obj: DropDownItem) => {
        if (!obj.isGroup) {
            let itemName = "";
            if (obj[this.model.itemNameProp]) {
                itemName = "" + obj[this.model.itemNameProp];
            } else if (obj.itemName) {
                itemName = "" + obj.itemName;
            } else if (obj.txt) {
                itemName = "" + obj.txt;
            }

            obj[this.model.itemNameProp] = itemName;
            obj.dropDownOrigName = obj.dropDownOrigName || itemName; //save original name so we can search against it
        }
    };

    mergeItemInfo = (source: DropDownItem, target: DropDownItem) => {
        if (source && target) {
            target.favorite = source.favorite;
            target.itemDesc = source.itemDesc;
            target.noAnchorClick = true;
            FavoriteApi.refreshInstances(target);
        }
    };

    onEach = (func: Function, itemList?: DropDownItem[], earlyExit?: boolean, path?: number[]): any => {
        path = path || []; //path is tree path from root to leaf for this item
        itemList = itemList || this.model.items || [];
        let ret;
        for (let i = 0; i < itemList.length; i++) {
            //loop original items
            path.push(i); //push i to path
            let item = itemList[i];
            ret = func(item, path); // always process item

            if (earlyExit && ret) {
                //early exit if ret is truthy and earlyExit is configured as true
                return ret;
            }

            if (item.items) {
                //nested items
                ret = this.onEach(func, item.items, earlyExit, path); //note: path is passed so it accumulates
            }

            if (earlyExit && ret) {
                //early exit if ret is truthy and earlyExit is configured as true
                return ret;
            }

            path.pop(); //pop i from path
        }
        return ret;
    };

    //Note: this creates group headings in the dropdown
    //eg, searches come in as a list of searches available. We want extra items though to indicate which group some search is under so we add them here.
    addGroups = (arr: DropDownItem[]) => {
        if (arr && this.groups) {
            //if items array and groups exist
            //remove groups from arr if they exist
            for (let i = arr.length - 1; i >= 0; i--) {
                if (arr[i] && arr[i].isGroup) {
                    arr.splice(i, 1);
                }
            }

            let groupNameProp = this.model.groupNameProp;
            for (let g = 0; g < this.groups.length; g++) {
                //loop groups
                this.hasGroups = true;
                this.groups[g].val = null;
                this.groups[g].itemId = null;
                this.groups[g].isSelected = null; //groups cannot be selected
                this.groups[g].dropDownOrigName = null; //groups cannot be searched
                this.groups[g].dropDownUUID = null; //groups cannot be selected
                this.groups[g].isGroup = true; //indicate this is a group
                this.groups[g].groupName = this.groups[g][groupNameProp]; //set group name
                let idx = S25Util.array.findByProp(arr, groupNameProp, this.groups[g][groupNameProp]); //find first real item in this group
                if (idx > -1) {
                    //if found
                    if (!arr[idx].isGroup) {
                        //if this first item is not a group (ie, we haven't already inserted this group into the list)
                        arr.splice(idx, 0, this.groups[g]); //insert group at first index -- insert this group into the list for display in front of all of its group members
                    }
                }
            }
        }
    };

    setupItems = (items: DropDownItem[]) => {
        let isSelectedFound = false;
        this.onEach((obj: DropDownItem, path: number[]) => {
            //set some drop down specific values on the items
            this.setName(obj);

            //update last entry to max value + 1 to force the path as unique
            if (path[path.length - 1] <= this.maxPathVal) {
                path[path.length - 1] = this.maxPathVal + 1;
            }

            //update max val
            if (path[path.length - 1] > this.maxPathVal) {
                this.maxPathVal = path[path.length - 1];
            }

            //create a unique id per item so we can copy the array (when filtering) but still find the original (for selecting) and also for transclusion
            //note: keep the same dropdownUUID if it already exists so that this uuid stays the same for this object
            obj.dropDownUUID = obj.dropDownUUID || this.uuid + "_" + path.join("_");
            obj.noAnchorClick = true; //in case chosen item bc an s25-item, set this to prevent clicking to open details

            let isSelected =
                this.chosen &&
                (obj === this.chosen || (obj.val || obj.itemId) == (this.chosen.val || this.chosen.itemId)); //set isSelected on an item if it is equal to the chosen value
            if (!isSelectedFound && isSelected) {
                //only set isSelected once for first matching search
                obj.isSelected = true;
                isSelectedFound = true;
                this.chosen.dropDownOrigName = obj.dropDownOrigName;
                this.chosen.dropDownUUID = obj.dropDownUUID;
                this.mergeItemInfo(obj, this.chosen);
            } else {
                //set the rest to false
                obj.isSelected = false;
            }
        }, items);

        let groupNameProp = this.model.groupNameProp; //property name for group name in items
        if (groupNameProp) {
            //if groups exist
            this.groups = S25Util.array.uniqueByProp(
                S25Util.deepCopy(this.model.items, this.copySkipMap),
                groupNameProp,
            ); //extract unique items by group name
            this.groups = this.groups.filter((g) => {
                return g && g[groupNameProp];
            }); //only use truthy groups
            this.groups.sort((a, b) => {
                //sort group items by order-of-appearance in items array
                let aMinIndex = S25Util.array.findByProp(items, groupNameProp, a[groupNameProp]); //index of first appearance of a's groupName in items
                let bMinIndex = S25Util.array.findByProp(items, groupNameProp, b[groupNameProp]); //index of first appearance of b's groupName in items
                return aMinIndex - bMinIndex;
            });

            //add groups to items
            this.addGroups(items);
        }

        this.itemNum = this.model.items.length; //save length of item list
        this.isEmpty = this.model.items.length <= 0 && (!this.model.staticItems || this.model.staticItems.length <= 0); //save boolean to indicate if no items
    };

    searchFilterF = (obj: DropDownItem) => {
        //return objects that are not a group and the original name (NOT the replaced name) matches the search (case insensitive)
        return !obj.isGroup && new RegExp(S25Util.escapeRegExp(this.search), "i").test(obj.dropDownOrigName);
    };

    searchHighlighter = (obj: DropDownItem) => {
        //highlight matching letters in results (remember these objects are copies of the original set of items)
        if (this.search && obj[this.model.itemNameProp]) {
            //if search and item has a name
            //replace matching segments in name with spans containing them and a bold class
            //this bolds the matches in the dropdown but also changes the name such that it contains spans
            //to ensure these changes dont affect future regex searches, we always use dropDownOrigName in searchFilterF
            //note: "" + obj... bc some names come in a ints...
            obj[this.model.itemNameProp] = (obj.dropDownOrigName || obj[this.model.itemNameProp]).replace(
                new RegExp("(" + S25Util.escapeRegExp(this.search) + ")", "gi"),
                "<span class='ngBold'>$1</span>",
            );
        }
    };

    noGroups = (item: DropDownItem) => {
        return item && !item.isGroup;
    };

    getOrigItemByModelId = (item: DropDownItem, itemsOverride?: DropDownItem[]) => {
        if (!item) {
            return null;
        }

        itemsOverride = itemsOverride || this.model.items;
        let results: DropDownItem[] = [];
        this.onEach((ithItem: DropDownItem) => {
            if (
                !item.isGroup &&
                !ithItem.isGroup &&
                (ithItem === item ||
                    (!this.model.noItemIdForGet &&
                        S25Util.isDefined(ithItem.itemId) &&
                        S25Util.isDefined(item.itemId) &&
                        ithItem.itemId == item.itemId) ||
                    (S25Util.isDefined(ithItem.val) && S25Util.isDefined(item.val) && ithItem.val == item.val))
            ) {
                //collect all matching results
                results.push(ithItem);
            }
        }, itemsOverride);

        if (results.length === 1) {
            //return single result
            return results[0];
        } else if (results.length > 1) {
            //multiple results (eg, same itemId is in list of items due to static starred section + user section with same item (search))
            results = results.filter((ithItem) => {
                return ithItem.dropDownUUID === item.dropDownUUID;
            });
            if (results.length) {
                //return result with same uuid, which is guaranteed unique
                return results[0];
            }
        }
        return null;
    };

    getItems = () => {
        if (this.currResults && this.currResults.length) {
            return this.currResults.filter((item) => this.noGroups(item));
        } else if (!this.currResults && this.model.items && this.model.items.length) {
            return this.model.items.filter((item) => this.noGroups(item));
        } else {
            return [];
        }
    };

    getItem = (item: DropDownItem) => {
        return this.getOrigItemByModelId(item, this.getItems());
    };

    removeByModelId = (item: DropDownItem, itemsOverride?: DropDownItem[]) => {
        itemsOverride = itemsOverride || this.model.items;
        let origItem = this.getOrigItemByModelId(item, itemsOverride);
        if (origItem) {
            itemsOverride.splice(itemsOverride.indexOf(origItem), 1);
            this.removeByModelId(item, itemsOverride); //recur in case extra items have the same itemId (could be due to static items)
        }
    };

    refreshChosenSelection = () => {
        this.initChosen = false; //destroy and recreate chosen view in selected area, in order to recalc favorites, etc
        this.detectChanges();
        this.zone.run(() => {
            this.initChosen = true;
            this.detectChanges();
        });
        //return jSith.when();
    };

    getItemElem = (item: DropDownItem): Promise<any> => {
        item = this.getItem(item);
        if (item) {
            let defer = jSith.defer();
            this.zone.run(() => {
                let $item = jSith.findSingle(
                    this.elementRef.nativeElement,
                    "#ngDropdown" + (item.items ? "Array" : "") + "Id-" + item.dropDownUUID,
                );
                defer.resolve($item);
            });
            return defer.promise;
        }
        return jSith.when();
    };

    unHighlightItem = (item: DropDownItem) => {
        return this.getItemElem(item).then(($item: any) => {
            if ($item) {
                //$item.parent().blur();
                jSith.removeClass($item.parentNode, "ngDropdownPaginatedHover");
                jSith.removeAttr($item.parentNode, "aria-selected");
                jSith.removeAttr($item.parentNode, "role");
            }
        });
    };

    getScrollTopElem = () => {
        if (this.$scrollTopElem && document.body.contains(this.$scrollTopElem)) {
            return this.$scrollTopElem;
        }
        this.$scrollTopElem = jSith.findSingle(this.elementRef.nativeElement, ".ngDropdownScrollTopEl");
        return this.$scrollTopElem;
    };

    setScrollTop = (t: number) => {
        let $scrollTopElem = this.getScrollTopElem();
        t = Math.max(t, 0);
        if ($scrollTopElem) {
            $scrollTopElem.scrollTop = t;
        }
    };

    highlightItem = (item: DropDownItem) => {
        return this.getItemElem(item).then(($item: any) => {
            if ($item) {
                //$item.parent().focus(); (takes focus away from input in s25ql, which needs focus to catch ctrl + enter and fire "done()" on multiselect dropdowns)
                //note: above could be solved by passing "done" code into dropdown paginated and using ctrl + enter handlers in here instead of in s25-generic-multiselect-dropdown
                jSith.addClass($item.parentNode, "ngDropdownPaginatedHover");
                jSith.addAttr($item.parentNode, "aria-selected", "true");
                jSith.addAttr($item.parentNode, "role", "alert");
                let $scrollTopElem = this.getScrollTopElem();

                let adj = 30;
                let visibleTop = InfiniteScrollUtil.getScrollPosn($scrollTopElem);
                let visibleBottom = visibleTop + jSith.height($scrollTopElem);
                let scrollHeight = InfiniteScrollUtil.getScrollHeight($scrollTopElem);
                let itemTop = jSith.position($item).top;
                let itemHeight = jSith.height($item);
                let newScrollTop = visibleTop;

                if (itemTop > visibleBottom - adj || itemTop < visibleTop + adj) {
                    newScrollTop = Math.min(Math.max(0, itemTop - itemHeight / 2), scrollHeight);
                }

                this.setScrollTop(newScrollTop);
            }
        });
    };

    resetArrows = () => {
        this.searchError = false;
        this.$scrollTopElem = null;
        if (S25Util.isDefined(this.currItem)) {
            this.unHighlightItem(this.currItem);
            this.currItem = null;
            this.setScrollTop(0);
        }
    };

    hasMoreClientPages = () => {
        //return true if there are more items in array (itemNum) than shown in display (pageSize * pagesShown)
        return this.itemsLimit < this.maxint && this.pageSize * this.pagesShown < this.itemNum;
    };

    hasMorePages = () => {
        return this.hasMoreClientPages() || (this.model.serverSide && this.model.serverSide.hasMore());
    };

    scrollF = () => {
        //helper fn for infinite-scroll
        if (!this.scrollWorking) {
            this.scrollWorking = true;
            let deferred = jSith.defer();
            if (this.pagesShown < this.maxint && this.hasMoreClientPages()) {
                this.pagesShown++;
                this.itemsLimit = this.pageSize * this.pagesShown || this.maxint;
                deferred.resolve();
            } else if (this.model.serverSide && this.model.serverSide.hasMore()) {
                this.model.serverSide &&
                    this.model.serverSide.scrollF().then((newItems: DropDownItem[]) => {
                        if (newItems) {
                            this.setupItems(newItems);
                            Array.prototype.push.apply(this.model.items, newItems);
                            this.handleStaticItems();
                            this.model.items.map((item) => this.searchHighlighter(item));
                            this.currResults = [].concat(this.model.items);
                            this.pagesShown++;
                            this.itemsLimit = this.pageSize * this.pagesShown || this.maxint;
                            this.itemNum = this.model.items.length;
                        }
                        deferred.resolve();
                    });
            } else {
                deferred.resolve();
            }

            return deferred.promise.then(() => {
                return this.zone.run(() => {
                    this.scrollWorking = false;
                });
            });
        } else {
            return jSith.when();
        }
    };

    resetPagination = (newItemNum: number) => {
        //when searching, the list changes and therefore invalidates any pagination and DOM cleanup / cached invisible nodes...
        this.searchError = false;
        this.pagesShown = 1; //reset pages shown
        this.itemNum = newItemNum; //reset itemNum
        this.itemsLimit = this.pageSize; //reset items limit
    };

    cleanup = () => {
        //reset search, last search, reset pagination, delete pagination listener (it is recreated on open), remove any special highlightings...
        this.searchError = false;
        this.search = "";
        this.lastSearch = "";
        this.resetArrows();

        this.model.items = this.model.serverSide ? [] : this.model.items;
        this.handleStaticItems();
        this.currResults = [].concat(this.model.items);

        this.model.resetSelectedOnCleanup &&
            jSith.forEachObj(this.model.items, (item: DropDownItem) => {
                item.isSelected = false;
            });
        this.resetPagination((this.model.items && this.model.items.length) || 0);
        this.model.serverSide && this.model.serverSide.reset(); //reset server side pagination too
    };

    markSelectedOrig = (item: DropDownItem, skipEmit?: boolean) => {
        let origItem: DropDownItem;
        if (item) {
            item.isSelected = true;
            origItem = this.getOrigItemByModelId(item);
            if (origItem) {
                origItem.isSelected = true; //set original item to selected
                origItem[this.model.itemNameProp] = origItem.dropDownOrigName; //set orig name to avoid any highlighting text that was set
                this.chosen = origItem; //set chosen
                this.chosen.popoverClass = this.model.popoverClass;
                this.mergeItemInfo(item, this.chosen);
            } else {
                //make sure item has dropDownOrigName so it displays in the closed dropdown... (ANG-1742)
                this.cleanup(); //cleanup state, set name info on chosen, add it to static items if configured that way...
                origItem = this.getOrigItemByModelId(item); //select (new) original item
                if (origItem) {
                    //if orig item found, mark it as selected
                    origItem.isSelected = true;
                }
            }

            if (origItem) {
                this.currItem = origItem;
                !skipEmit && this.chosenChange.emit(this.currItem);
            }
        }

        this.onEach((obj: DropDownItem) => {
            //loop original items
            if (obj && (!origItem || obj !== origItem)) {
                obj.isSelected = false; //set other original items to not-selected
            }
        });

        this.refreshChosenSelection();
    };

    /*
    Marks the selected item(s) as selected by setting item.select = true
    deslects other items if "items" param is not an array - i.e. not multislect
     */
    markSelected = (items: DropDownItem | DropDownItem[], skipEmit?: boolean) => {
        //not multiselect dropdown
        if (!S25Util.array.isArray(items)) {
            let item = items as DropDownItem;
            let origItem: DropDownItem;
            if (item) {
                item.isSelected = true;
                origItem = this.getOrigItemByModelId(item);
                if (origItem) {
                    origItem.isSelected = true; //set original item to selected
                    origItem[this.model.itemNameProp] = origItem.dropDownOrigName; //set orig name to avoid any highlighting text that was set
                    this.chosen = origItem; //set chosen
                    this.chosen.popoverClass = this.model.popoverClass;
                    this.mergeItemInfo(item, this.chosen);
                } else {
                    //make sure item has dropDownOrigName so it displays in the closed dropdown... (ANG-1742)
                    this.cleanup(); //cleanup state, set name info on chosen, add it to static items if configured that way...
                    origItem = this.getOrigItemByModelId(item); //select (new) original item
                    if (origItem) {
                        //if orig item found, mark it as selected
                        origItem.isSelected = true;
                    }
                }

                if (origItem) {
                    this.currItem = origItem;
                    !skipEmit && this.chosenChange.emit(this.currItem);
                }
            }

            //deselect other items
            this.onEach((obj: DropDownItem) => {
                //loop original items
                if (obj && (!origItem || obj !== origItem)) {
                    obj.isSelected = false; //set other original items to not-selected
                }
            });
        } else {
            //multiselect, we don't need to do all the multiselect work
            items.forEach((item: DropDownItem) => {
                if (item) item.isSelected = true;
            });
        }

        this.refreshChosenSelection();
    };

    open = () => {
        if (!this.isOpen) {
            this.zone.run(() => {
                this.isOpen = true;

                if (this.model.searchEnabled) {
                    setTimeout(() => {
                        let input = this.elementRef.nativeElement.querySelector("#searchAutoFocus");
                        input && input.focus();

                        /* Scrolling dropdown into view when opened for accessibility while maintaining focus
                           on input for search functionality */
                        let dropDown = this.elementRef.nativeElement.querySelector(".select2-drop-active");
                        dropDown && dropDown.scrollIntoView({ block: "center", inline: "nearest", behavior: "smooth" });
                    }, 250);
                }

                if (this.chosen) {
                    //Multiselect dropdown can have chosen as an array
                    this.markSelected(this.chosen, true);
                    if (this.model.scrollToSelected) {
                        let item = this.getItem(this.chosen);
                        if (item && item.dropDownUUID) {
                            this.zone.run(() => {
                                setTimeout(() => {
                                    if (this.isOpen) {
                                        this.highlightItem(item);
                                    }
                                }, 125);
                            });
                        }
                    }
                }

                this.isOpenEmit.emit(this.isOpen);
                this.detectChanges();
            });

            DropdownApi.close(document.body, this.id);
        }
    };

    close = () => {
        //cleanup and close drop down
        this.zone.run(() => {
            if (!this.model.alwaysOpen && this.isOpen) {
                this.cleanup();
                this.isOpen = false;
                this.isOpenEmit.emit(this.isOpen);
                this.detectChanges();
            }
        });
    };

    toggle = () => {
        //cleanup and open/close drop down
        if (this.model.alwaysOpen && this.isOpen) {
            return;
        }

        if (this.isOpen) {
            this.close();
        } else {
            this.open();
        }
    };

    autoOpenFun = () => {
        this.toggle();
        this.resetArrows();
    };

    select = (item: DropDownItem, event?: any) => {
        //set chosen to the original item object (NOT the copied filter-object)
        event && event.stopPropagation && event.stopPropagation();

        if (item && item.items && item.items.length) {
            //nested item
            item.toggleCollapse();
        } else if (item) {
            //not nested item
            if (this.model.selectOverride) {
                this.zone.run(() => {
                    this.model.selectOverride(item); //do something to item
                    this.refreshChosenSelection();
                    this.getOrigItemByModelId(item).isSelected = item.isSelected; //set orig item to same selected as item (item could be search result item so could be diff items...)
                });
            } else {
                this.markSelected(item);
                if (this.onSelect) {
                    //run any onSelect callback
                    this.zone.run(() => {
                        this.onSelect(this.chosen);
                    });
                }
                this.close(); //close drop down
            }
        }
    };

    /*
     * Static items include currently selected item, group headers and maybe favorites??
     *  - We maintain model.items and results and curResults
     *  - causing intermitant significant slowdown
     */
    handleStaticItems = () => {
        this.model.staticItems = this.model.staticItems || [];
        jSith.forEachObj(this.model.staticItems, (obj: any) => this.setName(obj));

        let results: DropDownItem[];
        if (this.search) {
            //note we do NOT add all matching items from currResults to static items bc
            //results could be paginated and thus you'd have to scroll down to add the
            //favorite item, only to have it ripped out and placed at the top; so the difference
            //bt a WS match and this simple searchFilterF is acceptable
            results = this.model.staticItems.filter((item) => this.searchFilterF(item));
        } else {
            results = this.model.staticItems.filter((item) => this.noGroups(item));
        }

        jSith.forEachObj(results, (r: DropDownItem) => {
            this.removeByModelId(r);
        });
        results.sort(S25Util.shallowSort("itemName", null, true));

        //move chosen to top only if no groups since items need to stay in their group in that case
        if (this.chosen && this.model.chosenAtTop) {
            //merge any additional info into chosen (like a new name in results or grp)
            let origItem = this.getOrigItemByModelId(this.chosen, results);
            this.chosen.grp = (origItem && origItem.grp) || this.chosen.grp;
            this.chosen.itemName = (origItem && origItem.itemName) || this.chosen.itemName;
            this.chosen.txt = (origItem && origItem.txt) || this.chosen.txt;
            this.setName(this.chosen);

            this.removeByModelId(this.chosen, results);
            this.removeByModelId(this.chosen);
            results.push(this.chosen);
        }

        this.setupItems(results); //run setup on results
        results = results.filter((item) => this.noGroups(item)); //but remove groups that setupItems might have created

        jSith.forEach(results, (i: number, r: DropDownItem) => {
            r.lastStaticItem = i === 0; //mark last static item (note first is last due to unshift below...)
            this.model.items.unshift(r); //note we unshift due to results.sort being desc, AND we need these items at the TOP of the full this.model.items list anyway
        });

        this.setupItems(this.model.items); //run final setupItems to add any groups
    };

    addChosen = () => {
        if (this.chosen) {
            this.setName(this.chosen);
            //avoid pushing empty items to the dropdown especially important for multi select
            if (!this.getItem(this.chosen) && !S25Util.isEmptyBean(this.chosen)) {
                this.model.items.push(this.chosen);
            }
            this.markSelected(this.chosen, true);
        }
    };

    enterSelect = (e: any) => {
        this && this.currItem && this.select(this.getItem(this.currItem), e);
    };

    flatten = (items: DropDownItem[]) => {
        let flatItems: DropDownItem[] = [];
        jSith.forEachObj(items, (item) => {
            if (!item.isGroup) {
                flatItems.push(item);
                if (item.items && !item.isCollapsed) {
                    flatItems = flatItems.concat(this.flatten(item.items));
                }
            }
        });
        return flatItems;
    };

    arrow = (dir: "up" | "down" | "leftRight") => {
        let flatItems = this.flatten(this.getItems());
        if (flatItems.length) {
            let origCurrItem = this.currItem;
            if (!this.currItem) {
                this.currItem = flatItems[0];
            } else {
                let idx = flatItems.indexOf(this.currItem);
                if (idx === -1) {
                    this.currItem = flatItems[0];
                } else {
                    if (dir === "up") {
                        if (idx > 0) {
                            this.currItem = flatItems[idx - 1];
                        }
                    } else if (dir === "down") {
                        //down
                        if (idx < flatItems.length - 1) {
                            this.currItem = flatItems[idx + 1];
                        }
                    } else if (dir === "leftRight") {
                        this.getItemElem(this.currItem).then((item) => {
                            const favStar = jSith.find(item, ".ngSearchSelectFavorite");
                            if (favStar) this.toggleFavorite(this.currItem, favStar);
                        });
                    }
                }
            }

            this.unHighlightItem(origCurrItem).then(() => {
                this.highlightItem(this.currItem).then(() => {
                    this.detectChanges();
                });
            });
        }
    };

    toggleFavorite(item: any, element: any) {
        if (this.isLoggedIn) {
            jSith.addClass(element, "ngMinImgDim ngCpointer");

            FavoriteService.toggle(item);
            item.isFav = !item.isFav;
            item.favorite = item.isFav ? "T" : "F";

            FavoriteApi.refreshInstances({
                isFav: item.isFav,
                itemId: item.itemId,
                itemTypeId: item.itemTypeId,
            });

            jSith.removeClass(element, "ngStarOn");
            jSith.removeClass(element, "ngStarOff");
            if (item.isFav) {
                jSith.addClass(element, "ngStarOn");
            } else {
                jSith.addClass(element, "ngStarOff");
            }
        }
    }

    searchF = () => {
        //function used to filter objects (passed to set-repeat in the template, which actually runs the search)
        if (S25Util.isDefined(this.search) && this.search !== this.lastSearch) {
            //if search exists and has changed
            this.lastSearch = this.search; //save new search
            this.resetArrows();
            if (this.model.serverSide) {
                if (this.search.length < 2) {
                    //must send 2 chars or more...
                    this.model.items.splice(0);
                    this.handleStaticItems();
                    this.resetPagination(0);
                    this.model.serverSide && this.model.serverSide.reset();
                    this.currResults = [].concat(this.model.items);
                    this.cd.detectChanges();
                    return jSith.when(this.currResults);
                }

                S25LoadingApi.init(this.elementRef.nativeElement);
                return this.model.serverSide
                    .search(this.search)
                    .then((items: any) => {
                        if (items === "oldPromise") {
                            //old promise came back, which we can discard
                            S25LoadingApi.destroy(this.elementRef.nativeElement);
                            this.cd.detectChanges();
                            return jSith.when();
                        }
                        if (items && items.length) {
                            //actual items from a fresh data call returned
                            this.model.items.splice(0); //remove all items
                            Array.prototype.push.apply(this.model.items, items); //add new items
                            this.setupItems(this.model.items); //setup new items
                            this.handleStaticItems();
                            let results = S25Util.deepCopy(this.model.items, this.copySkipMap);
                            this.resetPagination((results && results.length) || 0);
                            results.map((item: any) => this.searchHighlighter(item));
                            this.addGroups(results);
                            this.zone.run(() => {
                                this.infiniteDataPromise.resolve();
                            });
                            this.currResults = results;
                            S25LoadingApi.destroy(this.elementRef.nativeElement);
                            this.cd.detectChanges();
                            return jSith.when(results);
                        } else {
                            //no items for fresh search, so set empty array of results
                            this.currResults = [];
                            S25LoadingApi.destroy(this.elementRef.nativeElement);
                            this.detectChanges();
                            //return jSith.when([]);
                        }
                    })
                    .catch((err) => {
                        this.currResults = [];
                        this.searchError = true;
                        S25LoadingApi.destroy(this.elementRef.nativeElement);
                        this.detectChanges();
                    });
            } else {
                let results = S25Util.deepCopy(this.model.items, this.copySkipMap).filter(this.searchFilterF); //COPY items and run search filter against it
                this.handleStaticItems();
                this.resetPagination((results && results.length) || 0); //reset pagination since the list has changed
                results.map((item: any) => this.searchHighlighter(item)); //highlight matches
                this.addGroups(results); //add groups to results since search results only contain actual items
                this.currResults = results;
                this.cd.detectChanges();
                return jSith.when(results); //return results to set-repeat
            }
        } else {
            this.cd.detectChanges();
            return jSith.when(); //return empty results to set-repeat
        }
    };

    ngOnInit() {
        this.pageSize = this.model.pageSize || this.pageSize;
        this.itemsLimit = this.pageSize;
        if (this.model.serverSide) {
            this.typeaheadDelay = 750;
        }
        this.model.itemNameProp = this.model.itemNameProp || "itemName";
        this.model.chosenAtTop = this.model.chosenAtTop || !!this.model.serverSide;
        jSith.forEachObj(this.model.items, (obj: any) => {
            this.setName(obj);
        });
        this.addChosen();
        this.zone.run(() => {
            //required so that callers dont have to have *ngIf on some init value
            this.model.staticItems = this.model.staticItems || [];
            this.setupItems(this.model.items);
            this.handleStaticItems();
            this.infiniteDataPromise = jSith.defer();
            if (!this.model.serverSide) {
                this.zone.run(() => {
                    this.infiniteDataPromise.resolve();
                });
            }
            this.currResults = this.currResults || [].concat(this.model.items);
            this.scrollBean = {
                scrollThreshold: this.model.scrollToSelected ? -1 : 80,
                infiniteScrollAction: this.scrollF,
                hasMorePages: this.hasMorePages,
                callUntilScrollbar: true,
                containerSelector: "#" + this.id + " .ngDropdownDrop",
                topSelector: this.topSelector,
                injectSelector: this.topSelector,
                itemSelector: "#" + this.id + " .ngDropdownItemEl",
                isLazy: 0,
                isDOMCleanup: 0,
                itemCacheBuffer: 1000,
                eventCopy: 0,
                clearInfiniteScrollEvent: "S25InfiniteScrollDropdownPaginatedClear",
                deleteListenerEvent: "S25InfiniteScrollDropdownPaginatedDeleteListener",
                refreshListenerEvent: "S25InfiniteScrollDropdownPaginatedRefreshListener",
                dataPromise: this.infiniteDataPromise,
            };

            UserprefService.getLoggedIn().then((isLoggedIn) => {
                this.isLoggedIn = isLoggedIn; //used in children (see s25-generic-dropdown uses $transcludeCtrl.isLoggedIn)
                this.init = true;
                this.model.autoOpen &&
                    this.zone.run(() => {
                        this.autoOpenFun();
                    });
                this.detectChanges();
            });
        });

        //expose select to model for outsiders to call
        this.model.select = this.select;

        //expose function for outsiders to get first element in filtered list
        this.model.getFirstElement = () => {
            let items = this.getItems();
            let item = items && items.length && S25Util.deepCopy(items[0], this.copySkipMap);
            if (item) {
                item[this.model.itemNameProp] = item.dropDownOrigName; //reset to orig name in case it has highlighting elements in it
                return item;
            } else {
                return null;
            }
        };

        //expose function for outsiders to get first element id in filtered list
        this.model.getFirstElementId = () => {
            let e = this.model.getFirstElement();
            return e && (e.val || e.itemId);
        };

        //expose function for outsiders to get highlighted index in list (or null if nothing highlighted)
        this.model.getHighlightedItem = () => {
            return this.currItem;
        };

        //expose function for outsiders to know if dd is open
        this.model.isDropdownOpen = () => {
            return this.isOpen;
        };
    }

    ngOnDestroy(): void {
        this.cleanup();
        this.$scrollTopElem = null;
    }

    ngOnChanges(changes: SimpleChanges): void {
        let change = changes && changes["chosen"];
        let prev = change && change.previousValue;
        let curr = change && change.currentValue;

        if (change && curr !== prev) {
            // task page this.chosen is somehow just the string of the chosen search, eg "Outstanding"
            // so resolve that to a real item
            if (curr && typeof curr !== "object") {
                curr = this.getItem({ itemId: curr, val: curr });
                if (curr) {
                    this.chosen = curr;
                }
            }
            this.markSelected(this.chosen, true);
        }

        if (changes.model) {
            this.cleanup();
            this.cd.detectChanges();
        }
    }

    // **** adding setTimeout fixed view destory error in console, but did not pass unit test. calendar calendar dao-directive calendar accessibility FAILED
    // tried jSith.timeout still failed
    detectChanges() {
        try {
            //setTimeout(() => {
            window.angBridge.$timeout(() => {
                this.cd && !(this.cd as ViewRef).destroyed && this.cd.detectChanges();
            });
            //}, 10);
        } catch (error: any) {}
    }
}
